import React from 'react';
import { Box } from '@mui/material';
import Select from 'react-select';

export type PaginationType = {
  pageSize: number;
  currentPage: number;
  totalItems: number | null;
};

const PageSizeSelector = ({
  pagination,
  setPagination
}: {
  pagination: PaginationType;
  setPagination: (a: PaginationType) => void;
}) => {
  const pageSizeOptions = [5, 10, 25, 50, 100, 250, 500];

  return (
    <Box display="flex" justifyContent="flex-end" alignItems={'center'}>
      Résultats par page:{' '}
      <Box sx={{ ml: 1, display: 'inline-block', width: '85px' }}>
        <Select
          id={'pageSize-select'}
          options={pageSizeOptions.map((pageSizeOption) => {
            return { label: pageSizeOption, value: pageSizeOption };
          })}
          onChange={(pageSize: any) => {
            setPagination({
              ...pagination,
              pageSize: pageSize.value,
              currentPage: 1
            });
            localStorage.setItem('pageSize', pageSize.value);
          }}
          value={{ value: pagination.pageSize, label: pagination.pageSize }}
          loadingMessage={() => 'Chargement en cours...'}
          noOptionsMessage={() => 'Aucune valeur à sélectionner'}
          placeholder=""
          menuPlacement="auto"
        />
      </Box>
    </Box>
  );
};

export default PageSizeSelector;
