import React from 'react';
import { Box, Pagination, Typography } from '@mui/material';
import { PaginationType } from './PageSizeSelector';

const PaginationComponent = ({
  displayResultsNumber = false,
  pagination,
  setPagination
}: {
  displayResultsNumber?: boolean;
  pagination: PaginationType;
  setPagination: (a: PaginationType) => void;
}) => {
  const totalPages = pagination.totalItems
    ? Math.ceil(pagination.totalItems / pagination.pageSize)
    : 0;

  return (
    <Box display="flex" justifyContent={displayResultsNumber ? 'space-between' : 'flex-end'}>
      {displayResultsNumber && (
        <Typography fontSize="20px">
          {pagination?.totalItems} résultat{pagination?.totalItems !== 1 && 's'}
        </Typography>
      )}
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={pagination.currentPage}
          onChange={(event: any, page: number) => {
            setPagination({ ...pagination, currentPage: page });
          }}
        />
      )}
    </Box>
  );
};

export default PaginationComponent;
